<template>
  <div class="zt-page-content">
    <div class="page-header">数据看板</div>
    <div class="page-overview">
      <el-card class="box-card">
        <div class="title">商品一级类目排序</div>
        <div id="sort-first-category"></div>
      </el-card>
      <el-card class="box-card">
        <div class="title">风格排序</div>
        <div id="sort-first-style"></div>
      </el-card>
      <div>
        <el-card class="box-card nest-distribution">
          <div class="title">套系分布</div>
          <div class="nest-distribution-detail">
            <div class="title">
              <div>
                <span>{{ nestDistribution.yes }}</span>
              </div>
              <div>总套系文件</div>
            </div>
            <div class="title">
              <div>
                <span>{{ nestDistribution.no }}</span>
              </div>
              <div>单品数</div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card price-distribution">
          <div class="title">价格分布</div>
          <div id="price-distribution"></div>
        </el-card>
      </div>
      <el-card class="box-card product-distribution">
        <div class="title">商品分布</div>
        <div class="product-distribution-item title-up-down flex flex-justify-around">
          <div>
            <div class="title-number">
              <span>{{ goodsDistribution.total }}</span>
            </div>
            <div class="title-text">SPU已填</div>
          </div>
          <div>
            <div v-if="goodsDistribution" class="title-number">
              <span>{{ goodsDistribution.sku_total || 0 }}</span>
            </div>
            <div class="title-text">SKU已填</div>
          </div>
        </div>
        <div class="product-distribution-item shelf-message">
          <div class="title-up-down border-right">
            <div class="title-number">
              <span
                >{{
                  goodsDistribution.market && goodsDistribution.market.publish
                }}/{{
                  goodsDistribution.salebook &&
                    goodsDistribution.salebook.publish
                }}</span
              >
            </div>
            <div class="title-text">集市已上架/<br />销售手册已上架</div>
          </div>
          <div class="title-up-down border-right">
            <div class="title-number">
              <span
                >{{
                  goodsDistribution.market &&
                    goodsDistribution.market.unpublish
                }}/{{
                  goodsDistribution.salebook &&
                    goodsDistribution.salebook.unpublish
                }}</span
              >
            </div>
            <div class="title-text">集市未上架/<br />销售手册未上架</div>
          </div>
          <div class="title-up-down border-right">
            <div class="title-number">
              <span>{{ goodsDistribution.del }}</span>
            </div>
            <div class="title-text">已删除</div>
          </div>
        </div>
        <div class="product-distribution-item media-message">
          <div class="title-up-down">
            <div class="title-number">
              <span>{{ goodsDistribution.has_story_photo }}</span>
            </div>
            <div class="title-text">有素材图</div>
          </div>
          <div class="title-up-down">
            <div class="title-number">
              <span>{{ goodsDistribution.has_size_photo }}</span>
            </div>
            <div class="title-text">有标高图</div>
          </div>
          <div class="title-up-down">
            <div class="title-number">
              <span>{{ goodsDistribution.has_video }}</span>
            </div>
            <div class="title-text">有视频</div>
          </div>
          <div class="title-up-down">
            <div class="title-number">
              <span>{{ goodsDistribution.has_cad_photo }}</span>
            </div>
            <div class="title-text">有CAD文件</div>
          </div>
          <div class="title-up-down">
            <div class="title-number">
              <span>{{ goodsDistribution.no_sku }}</span>
            </div>
            <div class="title-text">SKU未填</div> 
          </div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div class="title flex">
          <span class="flex-1">销售订单分布</span>
          <span style="font-size:14px;color:#737373;">按风格分类</span>
        </div>
        <el-radio-group v-model="tm_type" @change="initOrder()" style="margin:20px 0;">
          <el-radio :label="1">按月度</el-radio>
          <el-radio :label="2">按季度</el-radio>
          <el-radio :label="3">按年度</el-radio>
        </el-radio-group>
        <div id="sort-first-saleOrder"></div>
      </el-card>
    </div>
    <div class="page-body">
      <!-- 头部筛选条件 -->
      <el-card class="box-card">
        <div class="search-event-item">
          <div class="text item">
            筛选:
            <!-- <el-select
              @change="companyTypeChanged"
              v-model="selectConfig.selectedCategory"
              placeholder="类目"
              size="mini"
              filterable
            >
              <el-option
                v-for="item in selectConfig.searchCategoryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select> -->
            <el-cascader 
              @change="companyTypeChanged"
              :options="selectConfig.searchCategoryList" 
              :show-all-levels="false" 
              :props="{value:'id',label:'name',emitPath:false}"
              v-model="selectConfig.selectedCategory" 
              size="mini"
              placeholder="全部类目">
            </el-cascader>
          </div>
          <!-- <el-select
            @change="companyTypeChanged"
            v-model="selectConfig.selectedNest"
            placeholder="套系"
            size="mini"
          >
            <el-option
              v-for="item in selectConfig.searchNestList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <el-select
            @change="companyTypeChanged"
            v-model="selectConfig.selectedStyle"
            placeholder="风格"
            size="mini"
          >
            <el-option
              v-for="item in selectConfig.searchStyleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            @change="companyTypeChanged"
            v-model="selectConfig.selectedMarketStatus"
            placeholder="集市状态"
            size="mini"
          >
            <el-option
              v-for="(item,key) in selectConfig.searchMarketStatusList"
              :key="key"
              :label="item"
              :value="key"
            >
            </el-option>
          </el-select>
          <el-select
            @change="companyTypeChanged"
            v-model="selectConfig.selectedSaleStatus"
            placeholder="销售手册状态"
            size="mini"
          >
            <el-option
              v-for="(item,key) in selectConfig.searchSaleStatusList"
              :key="key"
              :label="item"
              :value="key"
            >
            </el-option>
          </el-select>
        </div>
        <el-input
          size="mini"
          style="width:170px"
          placeholder="请输入搜索关键字"
          @change="companyTypeChanged"
          clearable
          v-model="key"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-card>
      <!-- 表格内容 -->
      <div class="table-content" v-loading="loading">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="id" label="商品ID"></el-table-column>
          <el-table-column prop="photo_render" label="商品图片"  width="150">
            <template slot-scope="scope" @click="showImage(scope.row.photo_render)">
              <el-carousel v-if="(scope.row.photo_render && scope.row.photo_render.length>0)"   :interval="3000"  height="150px" style="width:150px" indicator-position="none">
                  <el-carousel-item v-for="(img,index) in  (scope.row.photo_render)" :key="index">
                      <img style="width:100%"  :src="`${IMG_URL_PRE}${img}`" alt="">
                  </el-carousel-item>
              </el-carousel>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
          <el-table-column prop="factory.name" label="所属工厂"></el-table-column>
          <el-table-column prop="module_no" label="商品型号"> </el-table-column>
          <el-table-column prop="cat.name" label="类目"> </el-table-column>
          <el-table-column prop="style.name" label="风格"> </el-table-column>
          <el-table-column prop="material" label="素材图" width="150"> 
            <template slot-scope="scope">
              <el-carousel v-if="(scope.row.photo_story && scope.row.photo_story.length>0)"   :interval="3000"  height="150px" style="width:150px" indicator-position="none">
                  <el-carousel-item v-for="(img,index) in  (scope.row.photo_story)" :key="index">
                      <img style="width:100%"  :src="`${IMG_URL_PRE}${img}`" alt="">
                  </el-carousel-item>
              </el-carousel>
            </template>
          </el-table-column>
          <el-table-column prop="elevation" label="标高图" width="150">
            <template slot-scope="scope">
              <el-carousel v-if="(scope.row.photo_size && scope.row.photo_size.length>0)"   :interval="3000"  height="150px" style="width:150px" indicator-position="none">
                  <el-carousel-item v-for="(img,index) in  (scope.row.photo_size)" :key="index">
                      <img style="width:100%"  :src="`${IMG_URL_PRE}${img}`" alt="">
                  </el-carousel-item>
              </el-carousel>
            </template> </el-table-column>
          <el-table-column prop="n_sku" label="有效 SKU"> </el-table-column>
          <el-table-column prop="market_stat" label="集市状态">
            <template slot-scope="scope">
              <span
                :style="{ color: enumStatus[scope.row.market_stat].color }"
                >{{ enumStatus[scope.row.market_stat].value }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="salebook_stat" label="销售手册状态">
            <template slot-scope="scope">
              <span
                :style="{ color: enumStatus[scope.row.salebook_stat].color }"
                >{{ enumStatus[scope.row.salebook_stat].value }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="start_price" label="最低价格">
            <template slot-scope="scope">
              <span style="color:#F66F6A">¥ {{ scope.row.start_price }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="background:#fff;padding:10px 0;margin-top:16px"
          background
          layout="prev, pager, next"
          @current-change="companyTypeChanged"
          :current-page.sync="pageConfig.page"
          :page-size="pageConfig.size"
          :total="pageConfig.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchTree } from '@/util';
import echarts from 'echarts';
import { getProductStatistics, getProductList, marketOrderNByStyle } from '@/service/bureau';
import { catList,styleList } from '@/service/dictionary';
import { IMG_URL_PRE } from '@/config';

export default {
  components: {},
  data() {
    const selectData = [
      { label: '南康1', value: 1 },
      { label: '南康2', value: 2 },
    ]
    return {
      IMG_URL_PRE,
      loading: true,
      enumPrice: {
        0: '500 以下',
        500: '501~1000',
        1000: '1001~5000',
        5000: '5000 以上',
      },
      enumStatus: {
        1: { value: '已上架', color: '#2BBAAE' },
        2: { value: '未上架', color: '#F7B500' },
        3: { value: '已删除', color: '#F66F6A' },
      }, //标签颜色枚举
      tableData: [],
      pageConfig: {
        page: 1,
        size: 20,
        total: 200,
      },
      tm_type: 1,
      nestDistribution: {}, //套系分布
      goodsDistribution: {}, //商品分布
      searchAddress: '',
      searchMember: '',
      selectConfig: {
        selectedCategory: '',
        searchCategoryList: selectData, //商品类目
        // selectedNest: '',
        // searchNestList: selectData, //商品套系
        selectedStyle: '',
        searchStyleList: selectData, //商品风格
        selectedMarketStatus: '',
        searchMarketStatusList: {0:'全部',1:'已上架',2:'已下架',3:'已删除'}, //商品集市状态
        selectedSaleStatus: '',
        searchSaleStatusList: {0:'全部',1:'已上架',2:'已下架',3:'已删除'}, //商品销售手册状态
      }, //下拉框配置
      key: '',
    }
  },
  created() {},
  mounted() {
    this.init()
  },
  methods: {
    /**
     * @desc 初始化页面
     * @return void
     */
    init() {
      getProductStatistics().then((data) => {
        //商品一级类目排序初始化
        if (data.cat && data.cat.length>0) {
          let arr = data.cat.sort((n1, n2) => {
            return n2.n_product - n1.n_product;
          })
          let catDataX = arr.map((item) => item.name)
          let catDataY = arr.map((item) => item.n_product)
          this.renderSortCard('sort-first-category', {
            dataX: catDataX.reverse(),
            dataY: catDataY.reverse(),
          })
        }

        //风格排序初始化
        if (data.style && data.style.length>0) {
          let arr = data.style.sort((n1, n2) => {
            return n2.n_product - n1.n_product;
          })
          let styleDataX = arr.map((item) => item.name)
          let styleDataY = arr.map((item) => item.n_product)
          this.renderSortCard('sort-first-style', {
            dataX: styleDataX.reverse(),
            dataY: styleDataY.reverse(),
          })
        }

        //套系分布初始化
        this.nestDistribution = data.set || {}

        //价格分布初始化
        if (data.price && data.price.length>0) {
          const priceData = data.price.map((price) => ({
            value: price.n_product,
            name: this.enumPrice[price.start],
          }))
          this.renderPriceDistribution('price-distribution', priceData.reverse())
        }
        //商品分布初始化
        this.goodsDistribution = {
          ...(data.product||{}), 
          market: data.market || {},
          salebook: data.salebook || {},
        }
      })
      //销售订单分布初始化
      this.initOrder();
      //表格数据初始化
      this.tableKanbanInit()
      //渲染类目下拉框
      this.categoryInit();
      //渲染风格下拉框
      this.styleInit();
    },
    initOrder() {
      marketOrderNByStyle({tm_type: this.tm_type}).then((res)=>{
        console.log("marketOrderNByStyle",res);
        //销售订单分布
        if (res && res.length>0) {
          let arr = res.sort((n1, n2) => {
            return n2.count - n1.count;
          })
          let catDataX = arr.map((item) => item.name)
          let catDataY = arr.map((item) => item.count)
          this.renderSortCard('sort-first-saleOrder', {
            dataX: catDataX.reverse(),
            dataY: catDataY.reverse(),
          })
        }
      })
    },
    //渲染类目下拉框
    categoryInit(){
      catList().then(data=>{
        let list = fetchTree(data, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
        this.selectConfig.searchCategoryList = [{name:'全部',id:0,children:[{name:'全部类目',id:'qb'}]}].concat(list)
        console.log(this.selectConfig.searchCategoryList)
      })
    },
    //渲染风格下拉框
    styleInit(){
      styleList().then(data=>{
        this.selectConfig.searchStyleList = [{'name':'全部风格',id:''}].concat(data)
        console.log(this.selectConfig.searchStyleList)
      })
    },
    /**
     * @desc 获得数据看板表格数据
     * @return {total:number,list:[]}
     */
    async getDataKanban() {
      let res = {}
      // 针对筛选类目---全部-全部类目
      if (this.selectConfig.selectedCategory=='qb') {
        this.selectConfig.selectedCategory = '';
      }
      const params = {
        cat_id:this.selectConfig.selectedCategory,
        style_no:this.selectConfig.selectedStyle,
        market_stat:this.selectConfig.selectedMarketStatus,
        handbook_stat:this.selectConfig.selectedSaleStatus,
        k:this.key,
        page:this.pageConfig.page,
        pagesize:this.pageConfig.size,
      }
      await getProductList(params).then(data=>{
        res = data;
      })
      return res
    },
    /**
     * @desc 渲染看板表格
     */
    tableKanbanInit() {
      // this.tableData = [];
      this.loading = true;
      this.getDataKanban().then((data) => {
          this.loading = false
          console.log(data)
          this.pageConfig.total = data.total
          this.tableData = Object.freeze(data.list)
      })
    },
    /**
     * @desc 渲染商品一级类目 | 风格 排序 图表
     */
    renderSortCard(id, { dataX, dataY }) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById(id))
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '0%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: 'category',
          data: dataX,
        },
        series: [
          {
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'right', //在上方显示
                  textStyle: {
                    //数值样式
                    color: '#595959',
                    fontSize: 12,
                  },
                },
                color: function(params) {
                  //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                  var colorList = ['#5AD8A6', '#73A0FA']
                  return colorList[params.dataIndex % 2]
                },
              },
            },
            barCategoryGap: '40%',
            type: 'bar',
            data: dataY,
          },
        ],
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
      var autoHeight = dataY.length * 40 + 40
      myChart.getDom().style.height = autoHeight + 'px'
      myChart.resize()
    },
    /**
     * @desc 渲染价格分布
     */
    renderPriceDistribution(id, data) {
      const colorList = ['#F8C42D', '#5B8FF9', '#5AD8A6', '#5D7092']
      var myChart = echarts.init(document.getElementById(id))
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          icon: 'circle',
          orient: 'vertical',
          right: 0,
          top: 0,
          data: data.map((item) => item.name),
        },
        series: [
          {
            name: '价格分布',
            type: 'pie',
            radius: '80%',
            center: ['35%', '50%'],
            data: data,
            label: {
              position: 'inner',
              formatter: '{d}%',
            },
            itemStyle: {
              color: function(params) {
                //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                return colorList[params.dataIndex % 4]
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
      myChart.setOption(option)
    },
    /**
     * @desc 搜索条件改变时触发的事件
     * @return void
     */
    companyTypeChanged() {
      // console.log(this.selectConfig, this.key, this.pageConfig.page)
      this.tableKanbanInit()
    },
  },
}
</script>
<style scoped src="./style.css"></style>
<style scoped lang="less">
.zt-page-content {
  .title-up-down {
    font-size: 18px;
    text-align: center;
    .title-text {
      color: #000000;
    }
    .title-number {
      color: #e02020;
    }
  }
  .border-right {
    border-right: 1px solid #e6e6e6;
  }

  > div {
    min-width: 1760px;
  }
  .page-body {
    padding: 15px;
    .el-tag {
      line-height: 22px;
      height: 22px;
    }
    ::v-deep .box-card {
      &.el-card {
        border: 1px solid #e6e6e6;
        background: #e6e6e6 !important;
      }
      .el-card__body {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .search-event-item {
          display: flex;
          align-items: center;
          > div + div {
            margin-left: 28px;
          }
        }
      }
    }
    .table-content {
      ::v-deep .el-table {
        thead .cell {
          color: #292929;
          font-size: 16px;
        }
        .el-table__body .el-table__row .cell {
          color: #4d4d4d;
        }
      }
    }
  }
  .page-overview {
    padding: 16px;
    margin-bottom: 16px;
    box-sizing: border-box;
    .title {
      font-size: 18px;
      color: #000000;
    }
    height: 454px;
    display: flex;
    justify-content: space-between;
    > div {
      flex: 1;
      & + div {
        margin-left: 16px;
      }
    }
    ::v-deep .el-card {
      height: 454px;
      overflow: auto;
      // width: 400px;
      .el-card__body {
        overflow: auto;
        > div[id^='sort'] {
          min-height: 232px;
          margin-top: 60px;
        }
      }
    }
    .nest-distribution {
      height: 144px;
      .nest-distribution-detail {
        display: flex;
        justify-content: space-around;
        margin-top: 21px;
        text-align: center;
        span {
          color: #e02020;
        }
      }
    }
    .price-distribution {
      height: 298px;
      margin-top: 12px;
      #price-distribution {
        height: 220px;
      }
    }
    .product-distribution {
      .product-distribution-item {
        padding: 44px 0 22px 0;
        margin: 0 -20px 0 -20px;
        & + .product-distribution-item {
          border-top: 1px solid #e6e6e6;
        }

        &.shelf-message,
        &.media-message {
          > div {
            flex: 1;
          }
          .title-up-down {
            height: 65px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          display: flex;
          .title-number {
            font-size: 14px;
          }
          .title-text {
            font-size: 12px;
            white-space: nowrap;
          }
        }
        &.media-message {
          .title-up-down {
            height: 45px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
</style>
